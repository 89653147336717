<template>
  <div class="container">
    <nav class="nav">
      <NavMenu :navlist="navlist" class="nav-left"></NavMenu>
      <div class="nav-right">
        <div class="nav-right-top">
          <p>{{ t('168') }}</p>
          <div class="icon_list">
            <div
              class="icon_btn blue_btn"
              @click="addMember"
              v-checkAuth="'user-member-add'"
            >
              <el-icon class="element_icon"> <CirclePlusFilled /> </el-icon
              >&nbsp;{{ t('209') }}
            </div>
            <div
              class="icon_btn blue_btn"
              @click="exportData"
              v-checkAuth="'user-member-export'"
            >
              <el-icon class="element_icon"> <Upload /> </el-icon>&nbsp;{{ t('647') }}
            </div>
            <div class="icon_btn blue_btn" v-checkAuth="'user-member-import'">
              <el-upload
                ref="uploadRef"
                action=""
                accept=".xlsx"
                :http-request="httpUploadFile"
                :limit="1"
                :show-file-list="false"
              >
                <template #trigger>
                  <el-icon class="element_icon"> <Download /> </el-icon
                  >&nbsp;{{ t('648') }}
                </template>
              </el-upload>
            </div>
            <div
              class="icon_btn red_btn"
              @click="batchDel"
              v-checkAuth="'user-member-delete'"
            >
              <el-icon class="element_icon"> <Delete /> </el-icon>&nbsp;{{ t('210') }}
            </div>
          </div>
        </div>
        <div class="nav-right-bottom">
          <search-bar
            v-model:searchContent="queryForm.keyword"
            :search-placeholder="t('649')"
            @on-search="getData(true)"
          />
          <ele-select
            v-model:selectVal="queryForm.roleId"
            :label="t('650')"
            :options="roleList"
            :labelname="'roleName'"
            :value="'id'"
            @on-select="getData(true)"
          />
          <div class="flex-a" style="margin-left: 1.25rem">
            <p style="color: #36e5ff; margin-right: 1rem">{{ t('651') }}</p>
            <el-cascader
              ref="teamRef"
              style="width: 11.5rem"
              class="common-input"
              :placeholder="t('175')"
              :options="teamList"
              :props="teamCasDer"
              :show-all-levels="false"
              @change="teamChange"
              filterable
              clearable
              popper-class="teamedit disabled_hide_cacader"
            />
          </div>
        </div>
      </div>
    </nav>
    <!-- 表格部分 -->
    <div class="content">
      <ele-table
        v-model:pageNo="queryForm.pageNo"
        v-model:pageSize="queryForm.pageSize"
        :table-column-array="tableColumnArray"
        :table-data="tableData"
        :total="queryForm.total"
        @handleNoChange="getData"
        @handleSizeChange="getData"
        @handleSelectionChange="handleSelectionChange"
      >
        <template #buttonHanlder>
          <el-table-column :label="t('610')" align="center" width="260px">
            <template #default="{ row }">
              <el-row>
                <el-col :span="6">
                  <span
                    class="blue_btn"
                    @click.stop="editMessage(row)"
                    v-checkAuth="'user-member-update'"
                    >{{ t('652') }}</span
                  >
                </el-col>
                <el-col :span="9">
                  <span
                    class="blue_btn"
                    @click.stop="resetPassword(row.id)"
                    v-checkAuth="'user-password-reset'"
                    >{{ t('653') }}</span
                  >
                </el-col>
                <el-col :span="9">
                  <span class="blue_btn" @click.stop="viewCertficate(row.id)"
                    >{{ t('654') }}</span
                  >
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </template>
      </ele-table>
    </div>
  </div>
  <!-- 添加组织组件 -->
  <AddOrganization
    v-if="addOrgShow"
    v-model:isVisible="addOrgShow"
    :operate-team-id="operateTeamId"
    :operate-team-name="operateTeamName"
    @reloadTree="getDepartTree"
    @reloadTable="getData"
  />
  <!-- 查看证书 -->
  <ViewCertficate
    v-if="isViewCertficate"
    v-model:isVisible="isViewCertficate"
    :member-id="memberId"
  />
  <!-- 修改 -->
  <EditMessage
    v-if="isEditMessage"
    v-model:isVisible="isEditMessage"
    :edit-title="editTitle"
    :tree-data="treeData"
    :chooseDepartmentId="currentTreeKey"
    :message-type="messageType"
    :item-member="itemMember"
    @addChange="addEditChange"
  />
  <!-- 重置密码 -->
  <ResetPassword
    v-if="isResetPassword"
    v-model:isVisible="isResetPassword"
    :reset-pass-user-name="resetPassUserName"
  />
  <AddMember v-if="isAddMember" v-model:isVisible="isAddMember" />
</template>

<script>
import { t } from '../../languages';
import { defineComponent, reactive, toRefs, ref, nextTick } from "vue";
import AddOrganization from "./components/add-organization.vue";
import member from "@/network/member";
import NavMenu from "@/components/common/nav-menu.vue";
import ViewCertficate from "./components/view-certficate.vue";
import EditMessage from "./components/edit-message.vue";
import ResetPassword from "./components/reset-password.vue";
import AddMember from "./components/add-member.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import download from "@/network/download";
import org from "@/network/org";

export default defineComponent({
  components: {
    NavMenu,
    AddOrganization,
    ViewCertficate,
    EditMessage,
    ResetPassword,
    AddMember,
  },
  setup() {
    const state = reactive({
      addPlaneDialogShow: false,
      isVisible: false,
      editTitle: "", //弹窗标题
      messageType: "", //提交类型
      itemMember: {}, //修改数据
      queryForm: {
        keyword: "",
        roleId: "",
        pageNo: 1,
        pageSize: 20,
        total: 0,
      },
      isViewCertficate: false, //查看证书
      isEditMessage: false, //重置密码
      isResetPassword: false, //重置密码
      isAddMember: false,
      resetPassUserName: "",
      teamCasDer: {
        value: "id",
        label: "teamName",
        checkStrictly: true,
        children: "children",
        expandTrigger: "hover",
        emitPath: false,
      },
    });
    const teamRef = ref(null);
    const memberId = ref(""); //
    /* 导入 */
    const methods = reactive({
      viewCertficate: (tableMemberId) => {
        state.isViewCertficate = true;
        memberId.value = tableMemberId;
      },
      editMessage: (item) => {
        state.isEditMessage = true;
        state.editTitle = t("655");
        state.messageType = "edit";
        state.itemMember = item;
      },
      resetPassword: (id) => {
        state.resetPassUserName = id;
        state.isResetPassword = true;
      },
      addMember: () => {
        state.isEditMessage = true;
        state.editTitle = t("656");
        state.messageType = "add";
      },
      addEditChange: () => {
        state.isEditMessage = false;
        getData();
      },
    });

    const treeData = ref([]);
    const currentTreeKey = ref("");
    const eleTree = ref(null);
    async function getDepartTree() {
      const res = await member.getTreeList();
      if (res.resultStatus) {
        treeData.value = res.resultData;
      }
      nextTick(() => {
        if (currentTreeKey.value !== "") {
          eleTree.value.setCurrentKey(currentTreeKey.value);
        }
      });
    }
    getDepartTree();

    // /* 点击树节点 */
    // function handleNodeClick(nodeData) {
    //   currentTreeKey.value = nodeData.id;
    //   getData(true);
    // }

    //获取团队名称列表
    const pid = ref(null);
    const teamList = ref([]);
    const getList = async () => {
      const res = await org.getTList();
      teamList.value = res.resultData;
    };
    getList();

    //选择器
    const teamChange = async (value) => {
      if (value != null) {
        pid.value = value ? value : 0;
        getData();
      } else {
        pid.value = value ? value : 0;
        getData();
        teamRef.value.togglePopperVisible();
      }
      teamRef.value.togglePopperVisible();
    };

    const addOrgShow = ref(false); //添加/编辑组织弹窗
    const operateTeamId = ref(""); //操作的组织id
    const operateTeamName = ref(""); //操作的组织名称

    /* 添加组织名称 */
    function addTeam() {
      operateTeamId.value = currentTreeKey.value;
      operateTeamName.value = "";
      addOrgShow.value = true;
    }

    /* 编辑组织名称 */
    function editTeam(node) {
      operateTeamId.value = node.key;
      operateTeamName.value = node.label;
      addOrgShow.value = true;
    }

    /* 删除组织 */
    function delTeam(node) {
      ElMessageBox.confirm(
        t("657"),
        t("130"),
        {
          confirmButtonText: t("218"),
          cancelButtonText: t("217"),
        }
      )
        .then(async () => {
          const res = await member.delTeam(node.key);
          if (res.resultStatus) {
            ElMessage.success(t("560"));
            getDepartTree();
          }
        })
        .catch(() => {
          ElMessage(t("549"));
        });
    }

    const roleList = ref([]);
    /* 获取角色列表 */
    async function getRoleList() {
      const res = await member.getRoleList();
      if (res.resultStatus) {
        roleList.value = res.resultData;
      }
    }
    getRoleList();

    const tableData = ref([]);

    /* 获取人员表格数据 */
    async function getData(flag) {
      // 重置页数
      if (flag) {
        state.queryForm.pageNo = 1;
      }
      const res = await member.getMemberList({
        teamId: pid.value,
        queryInfo: state.queryForm.keyword,
        pageNo: state.queryForm.pageNo,
        pageSize: state.queryForm.pageSize,
        roleId: state.queryForm.roleId,
      });
      if (res.resultStatus) {
        tableData.value = res.resultData.data;
        state.queryForm.total = res.resultData.total;
      }
    }
    getData();
    //获取全部列表
    const initGetData = () => {
      (currentTreeKey.value = ""),
        (state.queryForm.keyword = ""),
        (state.queryForm.roleId = "");
      getData();
    };
    const checkedIds = ref([]);
    /* 勾选Checkbox */
    function handleSelectionChange(selection) {
      const ids = [];
      for (let i = 0; i < selection.length; i++) {
        ids.push(selection[i].id);
      }
      checkedIds.value = ids;
    }

    /* 批量删除 */
    function batchDel() {
      if (checkedIds.value.length > 0) {
        ElMessageBox.confirm(
          t("658"),
          t("130"),
          {
            confirmButtonText: t("218"),
            cancelButtonText: t("217"),
          }
        )
          .then(async () => {
            await member.kickout({ ids: checkedIds.value });
            const res = await member.batchDelMember({
              ids: checkedIds.value,
            });
            if (res.resultStatus) {
              ElMessage.success(t("560"));
              getData();
            }
          })
          .catch(() => {
            ElMessage(t("549"));
          });
      } else {
        ElMessage.warning(t("659"));
      }
    }

    /* 导出 */
    function exportData() {
      if (checkedIds.value.length === 0) {
        download.downloadSource(
          "/api/v1/export/member",
          {
            queryInfo: state.queryForm.keyword,
            roleId: state.queryForm.roleId,
            teamId: currentTreeKey.value !== "" ? currentTreeKey.value : "0",
          },
          "application/vnd.ms-excel",
          "post"
        );
      } else {
        download.downloadSource(
          `/api/v1/export/memberById`,
          checkedIds.value,
          "application/vnd.ms-excel",
          "post"
        );
      }
    }

    // 导入
    async function httpUploadFile(option) {
      const res = await member.importMember({ file: option.file }, true);
      res.resultStatus && ElMessage.info(res.resultData);
      getData(true);
    }

    return {
      ...toRefs(state),
      ...toRefs(methods),
      treeData,
      // handleNodeClick,
      roleList,
      addOrgShow,
      getDepartTree,
      addTeam,
      editTeam,
      delTeam,
      operateTeamId,
      operateTeamName,
      // eleTree,
      tableData,
      getData,
      checkedIds,
      handleSelectionChange,
      batchDel,
      exportData,
      memberId,
      initGetData,
      // currentTreeKey,
      httpUploadFile,
      teamList,
      getList,
      teamChange,
      pid,
      teamRef,
    };
  },
  data() {
    return {
      navlist: [
        { id: 1, name: t("168"), path: "/personManageIndex" },
        { id: 2, name: t("660"), path: "/roleOrder" },
        { id: 3, name: t("661"), path: "/teamManage" },
      ],
      defaultProps: {
        children: "children",
        label: "teamName",
      },
      tableColumnArray: [
        { label: t("630"), prop: "account" },
        { label: t("662"), prop: "jobNumber" },
        { label: t("663"), prop: "username" },
        { label: t("650"), prop: "roleName" },
        { label: t("664"), prop: "teamName" },
        { label: t("665"), prop: "phone" },
      ],
    };
  },
});
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  .nav {
    display: flex;
    width: 100%;
    height: 6.875rem;
    .nav-left {
      width: 22.5rem;
      height: 100%;
    }
    .nav-right {
      width: calc(100% - 22.5rem);
      height: 100%;
      background: rgba(0, 28, 49, 0.8);
      .nav-right-top {
        display: flex;
        justify-content: space-between;
        padding-top: 0.8125rem;
        padding-left: 2.1875rem;
        padding-right: 0.625rem;
        width: 100%;
        height: 40%;
        p {
          font-size: 1.25rem;
          font-family: Source Han Sans CN;
          color: #36e5ff;
        }
        .icon_list {
          display: flex;
          align-items: flex-start;
          .icon_btn {
            height: 1.5rem;
          }
        }
        .element_icon {
          font-size: 1.125rem;
        }
      }
      .nav-right-bottom {
        display: flex;
        align-items: center;
        width: 100%;
        height: 60%;
        padding-left: 2.1875rem;
      }
    }
  }
  .content {
    width: 100%;
    height: calc(100% - 6.875rem);
    background: rgba(0, 28, 49, 0.8);
    .teamName {
      cursor: url("../../asset/img/edit-pen.png"), auto;
    }
    .teamName:hover {
      opacity: 0.7;
    }
  }
}
//重写面包屑样式
.el-breadcrumb {
  font-size: 1.25rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  /deep/.el-breadcrumb__inner,
  /deep/.el-breadcrumb__separator {
    color: #36e5ff;
  }
  /deep/.el-breadcrumb__inner:hover {
    color: #36e5ff;
    cursor: pointer;
    opacity: 0.7;
  }
}
:deep(.el-upload) {
  display: flex;
  flex-direction: row;
}
</style>
