<template>
  <!-- 添加组织 -->
  <ele-dialog
    :isVisible="isVisible"
    :dialogTitle="operateTeamName ? t('1165') : t('1166')"
    :dialogWidth="'23.75rem'"
    @on-cancel="$emit('update:isVisible', false)"
    @on-add="save"
  >
    <el-form
      ref="elFormRef"
      :model="elForm"
      :rules="formRules"
      label-width="80px"
      label-position="top"
      class="el_form"
       @submit.prevent
    >
      <el-form-item :label="t('758')" prop="teamName">
        <el-input class="common-input" v-model="elForm.teamName" :placeholder="t('1167')"></el-input>
      </el-form-item>
    </el-form>
  </ele-dialog>
</template>
<script>
import { t } from '../../../languages';
import { defineComponent, reactive, toRefs, ref } from 'vue';
import member from '@/network/member';
import { ElMessage } from 'element-plus';

export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    operateTeamId: {
      type: String,
      default: '',
    },
    operateTeamName: {
      type: String,
      default: '',
    },
  },
  emit: ['update:isVisible', 'reloadTree','reloadTable'],
  setup(props, { emit }) {
    const state = reactive({
      elForm: {
        teamName: '',
      },
      formRules: {
        teamName: [
          { required: true, message: t('1167'), trigger: 'blur' },
          {
            max: 20,
            message: t('1168'),
            trigger: 'blur',
          },
        ],
      },
    });

    const { operateTeamId, operateTeamName } = toRefs(props);
    if (operateTeamName.value !== '') {
      state.elForm.teamName = operateTeamName.value;
    }

    const elFormRef = ref(null);
    /* 保存 */
    function save() {
      if (state.elForm.teamName.trim() !== '') {
        console.log(state.elForm.teamName);
        elFormRef.value.validate(async (valid) => {
          if (valid) {
            if (operateTeamName.value !== '') {
              // 编辑
              if (state.elForm.teamName !== operateTeamName.value) {
                const res = await member.editTeam({
                  id: operateTeamId.value,
                  teamName: state.elForm.teamName,
                });
                if (res.resultStatus) {
                  ElMessage.success(t('713'));
                  emit('update:isVisible', false);
                  emit('reloadTree');
                  emit('reloadTable');
                }
              } else {
                emit('update:isVisible', false);
              }
            } else {
              // 新增
              const res = await member.addTeam({
                pid: operateTeamId.value === '' ? '0' : operateTeamId.value,
                teamName: state.elForm.teamName,
              });
              if (res.resultStatus) {
                ElMessage.success(t('713'));
                emit('update:isVisible', false);
                emit('reloadTree');
              }
            }
          } else {
            return false;
          }
        });
      }
    }

    return {
      ...toRefs(state),
      elFormRef,
      save,
    };
  },
});
</script>
<style lang="less" scoped>
.el_form {
  /deep/.el-form-item__label {
    color: #fff;
  }
}
</style>
