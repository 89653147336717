<template>
  <ele-dialog
    :isVisible="isVisible"
    :dialogTitle="t('1179')"
    :dialogWidth="'23.75rem'"
    @on-cancel="$emit('update:isVisible', false)"
    @on-add="save"
  >
    <el-form
      ref="elFormRef"
      :model="elForm"
      :rules="formRules"
      label-width="80px"
      label-position="top"
      @submit.prevent
    >
      <el-form-item
        prop="password"
        :label="t('1180')"
      >
        <el-input
          style="margin-top: 5px"
          class="common-input"
          v-model="elForm.password"
          :placeholder="t('637')"
          show-password
        ></el-input>
      </el-form-item>
    </el-form>
  </ele-dialog>
</template>
<script>
import { t } from '../../../languages';
import { defineComponent, toRefs, reactive, onMounted, ref } from "vue";
import userService from "@/network/user";
import members from "@/network/member";
import { store } from "@/store";
import { ElMessage } from "element-plus";
import JSEncrypt from "jsencrypt";
export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    resetPassUserName: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    onMounted(() => {
      const userInfo =
        store.state.user.userInfo || localStorage.getItem("userInfo");
      account.value = userInfo.account;
    });
    const state = reactive({
      elForm: {
        password: "",
      },
      formRules: {
        password: [{ required: true, message: t("637"), trigger: "blur" }],
      },
    });
    let publicKey = "";
    /* 获取公钥 */
    async function getPublicKey() {
      const res = await userService.getPublicKey();
      if (res.resultStatus) {
        publicKey = res.resultData;
      }
    }
    //重置密码
    const account = ref("");
    const { resetPassUserName } = toRefs(props);
    const elFormRef = ref(null);

    async function save() {
      elFormRef.value.validate(async (valid) => {
        if (valid) {
          await getPublicKey();
          const encryptor = new JSEncrypt();
          encryptor.setPublicKey(publicKey); // 设置公钥
          const encryptionNew = encryptor.encrypt(state.elForm.password);
          members
            .resetPassword({
              id: resetPassUserName.value,
              password: encryptionNew,
            })
            .then((res) => {
              if (res.resultStatus) {
                ElMessage({
                  message: t("1181"),
                  type: "success",
                });
                emit("update:isVisible", false);
              }
            });
        } else {
          return false;
        }
      });
    }
    //确认
    return {
      ...toRefs(state),
      account,
      save,
      elFormRef,
      getPublicKey,
    };
  },
});
</script>
<style lang="less" scoped>
/deep/.el-form-item__label {
  color: #fff;
}
</style>
