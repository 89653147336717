<template>
  <ele-drawer
    :drawerTitle="'查看证书'"
    :drawerWidth="600"
    @on-close="$emit('update:isVisible', false)"
  >
    <div class="addButton">
      <img
        class="hover_opacity"
        src="@/asset/img/personal-icon/upload.png"
        @click="addFly"
      />
      <span
        class="hover_opacity"
        style="padding-left: 0.5625rem"
        @click="addFly"
        >{{ t('860') }}</span
      >
    </div>
    <div
      class="addCertificate"
      v-if="isAdd"
      v-loading="addLoading"
      :element-loading-text="t('861')"
      element-loading-background="rgba(0, 67, 117, 0.8)"
    >
      <div class="my-book">
        <span>{{ t('862') }}：</span>
        <input v-model.trim="certificate.certificateName" @blur="checkInput" maxlength="20" />
        <p class="error-tip" v-if="tipshow">{{ t('863') }}</p>
      </div>
      <div>
        <span>{{ t('864') }}：</span>
        <div class="timeSelect">
          <el-date-picker
            v-model="certificate.deadtime"
            type="date"
            :placeholder="t('774')"
            value-format="YYYY-MM-DD"
            @blur="checkDate"
            @change="timeChange"
          />
          <p class="error-tip" v-if="tipshow1">{{timeText}}</p>
        </div>
      </div>

      <div class="uploadImg">
        <div class="title">{{ t('865') }}：</div>
        <el-upload
          accept=".jpg,.png,.svg"
          list-type="picture-card"
          :file-list="photoUrl"
          :before-upload="beforeAvatarUpload"
          :auto-upload="false"
          :limit="4"
          multiple
          :on-exceed="overTips"
        >
          <el-icon><CirclePlus /></el-icon>
          <div>{{ t('575') }}</div>
        </el-upload>
      </div>
      <div class="flyButton">
        <el-button
          @click="
            isAdd = false;
            tipshow = false;
            tipshow1 = false;
          "
          >{{ t('217') }}</el-button
        >
        <el-button
          style="background: rgba(0, 112, 195, 0.7)"
          @click="addCertificated"
        >
          确定
        </el-button>
      </div>
    </div>
    <!-- 证书列表 -->
    <div
      class="flyContent"
      style="border-top: 1px solid #0085e9"
      v-for="(item, index) in certificateList"
      :key="index"
      v-show="!certificateList[0].emptyList"
      v-loading="isEditid === item.id && updateLoading"
      :element-loading-text="t('867')"
      element-loading-background="rgba(0, 67, 117, 0.8)"
    >
      <div class="my-book">
        <span>{{ t('862') }}：</span>
        <span v-if="isEditid === item.id">
          <input
          v-model="updatedFly.certificateName"
          @blur="updateCheckInput"
          maxlength="20"
        />
          <p class="error-tip" v-if="updateTipshow&&isEditid === item.id">{{ t('863') }}</p>
        </span>
        <span class="contenBox" :title="item.certificateName" v-else>{{  item.certificateName.length>14?item.certificateName.slice(0,14)+'...': item.certificateName }} </span>
      </div>
      <div
        class="flyEdit hover_opacity"
        v-if="isEditid !== item.id"
        @click="updateCertificate(item.id)"
      >
        <img src="@/asset/img/personal-icon/edit.png" /><span>{{ t('674') }}</span>
      </div>
      <div
        class="flyEdit hover_opacity"
        v-if="isEditid !== item.id"
        @click="deleteFlyCertificate(item.id)"
      >
        <img src="@/asset/img/personal-icon/delete.png" /><span>{{ t('210') }}</span>
      </div>
      <div>
        <span>{{ t('864') }}：</span>

        <div class="timeSelect" v-if="isEditid === item.id">
          <el-date-picker
            v-model="updatedFly.deadtime"
            type="date"
            :placeholder="t('774')"
            value-format="YYYY-MM-DD"
            @change="updateTimeChange"
          />
          <p class="error-tip" v-if="updateTipsShow&&isEditid === item.id">{{timeText}}</p>
        </div>

        <span class="contenBox" v-else>{{ item.deadtime }} </span>
        <span
          v-if="timer[index] < 0"
          class="iconfont icon-gantanhao2"
          style="margin-left: 10px; color: red"
          >&nbsp;{{ t('868') }}</span
        >
        <span
          v-if="timer[index] < 30 && timer[index] >= 0"
          class="iconfont icon-gantanhao2"
          style="margin-left: 10px; color: #fce83b"
          >&nbsp;{{ t('869') }}</span
        >
      </div>
      <div class="uploadImg">
        <div class="title">{{ t('865') }}：</div>
        <el-upload
          action=""
          accept=".jpg,.png,.svg"
          list-type="picture-card"
          :on-change="changeFile"
          :file-list="updatePhotoUrl"
          :before-upload="beforeAvatarUpload"
          :auto-upload="false"
          v-if="isEditid === item.id"
          :limit="4"
          :on-exceed="overTips"
          multiple
        >
          <el-icon><CirclePlus /></el-icon>
          <div>{{ t('575') }}</div>
        </el-upload>
        <div v-else class="imgList">
          <div
            class="photoList"
            v-for="(imgUrl, index1) in item.imgList"
            :key="index1"
            style="position: relative"
          >
            <div>
              <el-image :src="imgUrl" lazy> </el-image>
              <img
                class="overdue"
                src="../../../asset/img/overdue.png"
                v-if="timer[index] < 0"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flyButton" v-if="isEditid === item.id">
        <span>
          <el-button @click="isEditid = ''">{{ t('217') }}</el-button>
          <el-button
            style="background: rgba(0, 112, 195, 0.7)"
            @click="saveFlyCertificate"
            >{{ t('284') }}</el-button
          >
        </span>
      </div>
    </div>
  </ele-drawer>
</template>
<script>
import { t } from '../../../languages';
import { CirclePlus } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { reactive, toRefs, defineComponent, ref, onBeforeMount } from "vue";
import userService from "@/network/user";
import common from "@/network/common";
export default defineComponent({
  props: {
    memberId: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    /* 图片上传列表 */
    const photoUrl = ref([]);
    const updatePhotoUrl = ref([]);

    /*证书初始化参数列表  */
    let certicateParmas = {
      id: "", //证书id
      memberId: "1529372103472447490", //用户id
      deadtime: "", //选泽证书时间
      certificateName: "", //证书名字
      emptyList: true,
      photoUrl: "", //图片字符串
      imgList: [], //图片数组
    };
    let data = reactive({
      isEditid: "", //判断是否编辑
      isAdd: false, //判断是否添加证书
      addLoading: false, //添加时加载动画
      updateLoading: false, //修改时加载动画
      certificate: Object.assign({}, certicateParmas),
      certificateList: [Object.assign({}, certicateParmas)], //证书列表
      updatedFly: Object.assign({}, certicateParmas), //修改时获取的证书数据
    });

    /* 检查证书上传的文件类型 */
    const beforeAvatarUpload = (rawFile) => {
      let imgType = ["image/jpg", "image/png", "image/svg"];
      if (!imgType.includes(rawFile.type)) {
        ElMessage.error("Avatar picture must be JPG format!");
        return false;
      }
      return true;
    };

    /* 图片文件改变 */
    function changeFile() {
      console.log(updatePhotoUrl.value);
    }

    /* 添加证书事件 */
    function addFly() {
      data.certificate = Object.assign({}, certicateParmas);
      data.isAdd = true;
      photoUrl.value = [];
    }
    /* 请求参数统一化 */
    function requestParams(urlList, flyData) {
      let params = {
        certificateName: flyData.certificateName,
        deadtime: flyData.deadtime,
        id: flyData.id,
        memberId: memberId.value,
        photoUrl: urlList.join(";"),
      };
      return params;
    }
    /* 保存添加证书 */
    const timeText = ref(t("870"));

    const checkInput = () => {
      if (data.certificate.certificateName !== "") {
        tipshow.value = false;
      }
    };
    const checkDate = () => {
      if (data.certificate.deadtime !== "") {
        tipshow1.value = false;
      }
    };
    const timeChange = (e) => {
      let time = +new Date();
      if(e!=null){
        if(data.certificate.deadtime!=null){
          if(+new Date(e) + 86400000 - 1000 < time){
            timeText.value = t("871");
            tipshow1.value = true;
          }
          if(+new Date(e) + 86400000 - 1000 > time){
          tipshow1.value = false;
        }
        }else{
            timeText.value = t("870");
            tipshow1.value = true;
        }
      }else{
        timeText.value = t("870");
        tipshow1.value = true;
      }
    }
    const updateCheckInput = () => {
      if (data.updatedFly.certificateName !== "") {
        updateTipshow.value = false;
      }
    };
    const updateTimeChange = (e) => {
      let time = +new Date();
      if(e!=null){
        if(data.updatedFly.deadtime!=null){
          if(+new Date(e) + 86400000 - 1000 < time){
            timeText.value = t("871");
            updateTipsShow.value = true;
          }
          if(+new Date(e) + 86400000 - 1000 > time){
            updateTipsShow.value = false;
          }
        }else{
          timeText.value = t("870");
          updateTipsShow.value = true;
        }
      }else{
        timeText.value = t("870");
        updateTipsShow.value = true;
      }
    }
    
    const tipshow = ref(false);
    const tipshow1 = ref(false);
    const updateTipshow = ref(false);
    const updateTipsShow = ref(false);
    async function addCertificated() {
      if (data.certificate.certificateName === "") {
        tipshow.value = true;
        return false;
      }
      if (
        data.certificate.deadtime === "" ||
        data.certificate.deadtime === null
      ) {
        tipshow1.value = true;
        return false;
      }
      if(tipshow.value || tipshow1.value) return;
      data.addLoading = true;
      const urlList = [];
      for (let i = 0; i < photoUrl.value.length; i++) {
        let res = await common
          .uploadFiles(
            { file: photoUrl.value[i].raw, systemName: "user" },
            true
          )
          .catch(() => {
            data.addLoading = false;
            ElMessage.error(t("873"));
          });
        if (res.resultStatus === false) {
          data.addLoading = false;
          return;
        }
        urlList.push(res.resultData.url);
      }
      let params = requestParams(urlList, data.certificate);
      userService
        .addCertificate(params)
        .then((res) => {
          res.resultStatus && ElMessage.success(t("874"));
          tipshow.value = false;
          data.addLoading = false;
          initCertificateList();
        })
        .catch(() => {
          data.addLoading = false;
          ElMessage.error(t("875"));
        });
      data.isAdd = false;
    }

    /* 获取编辑证书信息 */
    async function updateCertificate(id) {
      if (data.updateLoading) {
        ElMessage.warning(t("876"));
        return;
      }
      data.updatedFly = Object.assign({}, certicateParmas);
      updatePhotoUrl.value = [];
      let time = +new Date();
      await userService.editCertificate(id).then(
        (res) => {
          if (res.resultData) {
            data.updatedFly = res.resultData;
            data.updatedFly.deadtime = res.resultData.deadtime.slice(0, 10);
            const photoUrlList = res.resultData.photoUrl.split(";");
            photoUrlList.forEach((item) => {
              updatePhotoUrl.value.push({ name: t("877"), url: item });
            });
            updateTipshow.value = false;
            if(+new Date(data.updatedFly.deadtime) + 86400000 - 1000 < time){
              timeText.value = t("871");
              updateTipsShow.value = true;
            }else{
              updateTipsShow.value = false;
            }
          }
          console.log(updatePhotoUrl.value);
        },
        () => {
          ElMessage.error(t("878"));
        }
      );
      data.isEditid = id;
    }

    //时间戳转化为时间格式xxxx-xx-xx
    function timestampToTime(timestamp) {
      let date = new Date(timestamp);
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      return Y + M + D;
    }
    //比较两个时间差
    const timer = ref([]); //存时间差
    function diffTimeHour(oldTime, newTime) {
      const timeDiff =
        new Date(newTime.replace(/-/g, "/")).getTime() -
        new Date(oldTime.replace(/-/g, "/")).getTime();
      const formatTimeDiff = timeDiff / (3600 * 1000 * 24);
      timer.value.push(formatTimeDiff);
      console.log(timer.value);
    }
    /* 获取飞行证书列表 */
    const { memberId } = toRefs(props);
    async function initCertificateList() {
      data.certificateList = [Object.assign({}, certicateParmas)];
      await userService
        .getCertificateList(memberId.value)
        .then((res) => {
          if (res.resultData) {
            data.certificateList = [...res.resultData];
            timer.value = [];
            data.certificateList.forEach((item) => {
              item.deadtime = item.deadtime.slice(0, 10);
              item.imgList = item.photoUrl.split(";");
              const nowTime = timestampToTime(new Date().getTime());
              diffTimeHour(nowTime, item.deadtime);
              console.log(nowTime, item.deadtime);
            });
          }
        })
        .catch(() => {
          ElMessage.error(t("879"));
        });
    }

    /* 初始化列表 */
    onBeforeMount(() => {
      initCertificateList();
    });

    /* 保存修改的证书 */
    async function saveFlyCertificate() {
      if(updateTipsShow.value||updateTipshow.value) return;
      if (
        !updatePhotoUrl.value.length ||
        !data.updatedFly.certificateName ||
        !data.updatedFly.deadtime
      ) {
        if(data.updatedFly.certificateName===''){
          updateTipshow.value = true;
        }
        ElMessage.error(t("1183"));
        return;
      }
      const updateImglist = [];
      data.updateLoading = true;
      for (let i = 0; i < updatePhotoUrl.value.length; i++) {
        if (updatePhotoUrl.value[i].name === t("877")) {
          updateImglist.push(updatePhotoUrl.value[i].url);
        } else {
          //图片上传到服务器放回url
          let res = await common
            .uploadFiles(
              { file: updatePhotoUrl.value[i].raw, systemName: "user" },
              true
            )
            .catch(() => {
              data.updateLoading = false;
              ElMessage.error(t("880"));
            });
          if (res.resultStatus === false) {
            data.updateLoading = false;
            return;
          }
          updateImglist.push(res.resultData.url);
        }
      }
      let params = requestParams(updateImglist, data.updatedFly);
      await userService
        .saveCertificate(params)
        .then((res) => {
          res.resultStatus && ElMessage.success(t("881"));
        })
        .catch((error) => {
          console.log(error);
          ElMessage.error(t("880"));
        });
      data.updateLoading = false;
      data.isEditid = "";
      initCertificateList();
    }
    const overTips = () => {
      ElMessage.info(t("872"));
    };
    /* 删除证书 */
    function deleteFlyCertificate(id) {
      userService.deleteCertificate(id).then((res) => {
        res.resultStatus && ElMessage.success(t("882"));
        initCertificateList();
      });
    }

    return {
      /* 数据 */
      ...toRefs(data),
      photoUrl,
      updatePhotoUrl,
      /* 函数 */
      beforeAvatarUpload,
      addFly,
      addCertificated,
      tipshow,
      tipshow1,
      changeFile,
      updateCertificate,
      saveFlyCertificate,
      deleteFlyCertificate,
      diffTimeHour,
      timer,
      /* 图标 */
      CirclePlus,
      timestampToTime,
      checkInput,
      checkDate,
      overTips,
      timeChange,
      timeText,
      updateTimeChange,
      updateTipsShow,
      updateCheckInput,
      updateTipshow
    };
  },
});
</script>
<style lang="less" scoped>
.el-button {
  color: #ffffff;
}
.el-drawer__body {
  padding: 0px !important;
}
.addButton {
  background: rgba(0, 112, 195, 0.4);
  height: 5rem;
  line-height: 5rem;
  border-top: solid #0085e9 0.0938rem;
  border-bottom: solid #0085e9 0.125rem;
  text-align: center;
  img {
    width: 1.25rem;
    vertical-align: middle;
  }
}
.flyEdit {
  text-align: right;
  margin-right: 1.4375rem;
  margin-top: 0;
  span {
    margin-right: 0.9375rem;
    margin-left: 0.6875rem;
  }
}
.flyContent,
.addCertificate {
  padding-left: 3.5625rem;
  text-align: left;
  .my-book {
    display: inline-block;
    position: relative;
    .error-tip {
      color: red;
      font-size: 0.875rem;
      position: absolute;
      left: 7rem;
    }
  }
  & > div {
    padding-top: 2.625rem;
    .contenBox {
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #00deff;
    }
  }
  span:first-child {
    margin-right: 2rem;
  }
  .flyEdit {
    float: right;
    span {
      margin-right: 0.9375rem;
      margin-left: 0.6875rem;
    }
  }
  .uploadImg {
    display: flex;
    .title {
      flex-shrink: 0;
      margin-right: 2rem;
    }
    .imgList {
      display: flex;
      flex-wrap: wrap;
      .photoList {
        position: relative;
        .el-image {
          width: 9.9375rem;
          height: 6.625rem;
          margin-right: 1.75rem;
          vertical-align: top;
          margin-bottom: 2.625rem;
        }
        .overdue {
          position: absolute;
          top: 1.5625rem;
          left: 3.125rem;
          width: 3.125rem;
          height: 3.125rem;
        }
      }
    }
    .el-icon {
      font-size: 2.5rem;
      color: #00f5ff;
      text-align: center;
    }
  }
  .flyButton {
    text-align: right;
    margin-right: 2.25rem;
    padding-top: 0;
  }
  .el-button {
    width: 6.0625rem;
    height: 2.875rem;
    background: rgba(0, 28, 49, 0.7);
    border: 0;
    margin-bottom: 2.375rem;
  }
  .timeSelect {
    position: relative;
    padding: 0;
    display: inline-block;
    .error-tip {
      color: red;
      font-size: 0.875rem;
      position: absolute;
    }
    /deep/.el-input__wrapper {
      background: rgba(0, 28, 49, 0.7);
      border: 1px solid #00a1a8;
      height: 2.875rem;
      width: 21.625rem;
    }
    /deep/.el-input__inner {
      color: #ffffff;
    }
  }
  input {
    width: 21.25rem;
    height: 2.875rem;
    background: rgba(0, 28, 49, 0.7);
    border: 1px solid #00a1a8;
    color: #ffffff;
  }
}
</style>
