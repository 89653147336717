<template>
  <ele-dialog
    :isVisible="isVisible"
    :dialogTitle="editTitle"
    :dialogWidth="'48.5rem'"
    @on-cancel="$emit('update:isVisible', false)"
    @on-add="sava"
  >
    <el-form
      ref="elFormRef"
      :model="elForm"
      :rules="formRules"
      label-width="80px"
      label-position="top"
      class="flex-fsb editbox"
    >
      <div class="left">
        <el-form-item :label="t('663')" prop="userName">
          <el-input
            class="common-input"
            v-model.trim="elForm.userName"
            :placeholder="t('885')"
            @keyup="elForm.userName = elForm.userName.replace(/[ ]/g, '')"
            maxlength="10"
          ></el-input>
        </el-form-item>
        <el-form-item :label="t('664')" prop="organizationName">
          <el-cascader
            ref="teamRef"
            class="common-input"
            style="width: 100%"
            :show-all-levels="false"
            v-model="elForm.organizationName"
            :placeholder="t('1171')"
            :props="organizationCasDer"
            :options="organization"
            @change="organiChange"
            popper-class="teamedit disabled_hide_cacader"
          />
        </el-form-item>
        <el-form-item :label="t('665')" prop="telephoneNumber">
          <el-input
            class="common-input"
            v-model.trim="elForm.telephoneNumber"
            :placeholder="t('886')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="t('887')" prop="regionName">
          <el-cascader
            ref="cityRef"
            class="common-input"
            style="width: 100%"
            v-model="elForm.regionName"
            :show-all-levels="false"
            :placeholder="t('213')"
            :props="cityCasDer"
            :options="city"
            @change="cityChange"
            popper-class="teamedit disabled_hide_cacader"
          />
        </el-form-item>
      </div>
      <div class="right">
        <el-form-item :label="t('630')" prop="accountNumber">
          <el-input
            class="common-input"
            v-model="elForm.accountNumber"
            :placeholder="t('636')"
            @keyup="
              elForm.accountNumber = elForm.accountNumber.replace(/[ ]/g, '')
            "
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item :label="t('650')" prop="roleName">
          <el-select
            style="width: 100%"
            class="common-input"
            v-model="elForm.roleName"
            :placeholder="t('1172')"
            @change="roleChange"
          >
            <el-option
              v-for="item in role"
              :key="item.id"
              :label="item.roleName"
              :value="item.id"
              :disabled="item.disabled"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="t('662')" prop="jobNumber">
          <el-input
            class="common-input"
            v-model="elForm.jobNumber"
            :placeholder="t('1173')"
            maxlength="20"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </ele-dialog>
</template>
<script>
import { t } from '../../../languages';
import {
  defineComponent,
  reactive,
  toRefs,
  watchEffect,
  ref,
  watch,
} from "vue";
import members from "@/network/member";
import { ElMessage } from "element-plus";
import { store } from "@/store";
import {
  findP,
  flatten,
  openRegionAdcode,
  findChildren,
} from "@/utils/openRegion";
export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    editTitle: {
      type: String,
      default: () => {},
    },
    messageType: {
      type: String,
      default: () => {},
    },
    treeData: {
      type: Object,
      default: () => {},
    },
    itemMember: {
      type: Object,
      default: () => {},
    },
    chooseDepartmentId: {
      type: String,
      default: "",
    },
  },
  emits: ["update:isVisible", "addChange"],
  setup(props, { emit }) {
    const cityRef = ref(null);
    const teamRef = ref(null);
    const { itemMember, chooseDepartmentId } = toRefs(props);
    const thephone = (rule, value, callback) => {
      if (value) {
        let strRegex = /\d{11}$/;
        return !strRegex.test(value)
          ? callback(new Error(`请输入正确的11位手机号`))
          : callback();
      } else {
        callback();
      }
    };
    /* 工号检验 */
    const memberJobNumber = (rule, value, callback) => {
      if (value) {
        let strRegex = /^[0-9a-zA-Z]*$/;
        return !strRegex.test(value)
          ? callback(new Error(t(`工号只能是字母或者数字`)))
          : callback();
      } else {
        callback();
      }
    };
    // 账号验证
    const memberAccountNumber = (rule, value, callback) => {
      let rep = /^[A-Za-z0-9]+$/;
      if (value.length<2) {
        callback(new Error(t(`账号长度不能少于两个字符串`)));
      }else if(!rep.test(value)){
        callback(new Error(t(`禁止输入中文和特殊字符`)));
      } else {
        callback();
      }
    };
    // 获取角色列表
    const effect = async () => {
      if (props.isVisible == true) {
        let res = await members.getRoleDwonList();
        state.role = res.resultData;
      }
    };
    watchEffect(effect);
    fetch("json/area-tree.json")
      .then((d) => d.json())
      .then((res) => {
        const arr = store.state.system.openRegion;
        const isCountry = arr.some((item) => {
          return item === 1;
        });
        if (isCountry) {
          state.city = res[0].children;
        } else {
          const arr1 = arr.map((item) => {
            return findP(item, res[0].children);
          });
          const treeAdcode = openRegionAdcode(res[0].children); //这个树的全部adcode
          const arr2 = flatten(arr1); //需要的
          const arr3 = arr2.map((item) => {
            return findChildren(item, res[0].children); //补上缺少的区adcode
          });

          const needAdcode = Array.from(new Set([...arr2, ...flatten(arr3)]));
          const notAdcode = treeAdcode.filter(
            (v) => needAdcode.findIndex((el) => el === v) === -1
          ); //去重过滤需要的adcode

          const city = deepCopyIsOpenRegion(res[0].children); //添加禁用属性
          const needCity = delIsOpenRegion(city);
          state.city = needCity;

          // 递归遍历修改数据
          function deepCopyIsOpenRegion(source) {
            const target = []; //Array.isArray(source) ? [] : {}; //数组兼容
            for (const k in source) {
              if (typeof source[k] === "object") {
                target[k] = deepCopyIsOpenRegion(source[k]);
              } else {
                target[k] = source[k];
              }
            }
            if (notAdcode.length != 0) {
              notAdcode.map((item) => {
                if (target.adcode === item) {
                  target.disabled = true;
                }
              });
            }
            return target;
          }
        }
        // 去除禁用的数据
        function delIsOpenRegion(source) {
          const target = []; //Array.isArray(source) ? [] : {}; //数组兼容
          source.map((item) => {
            if (item.disabled != true) {
              target.push(item);
            }
          });
          return target;
        }

        if (props.messageType == "add") {
          // 新增拿上一次选择数据
          let addCity = JSON.parse(localStorage.getItem("personCity"));
          state.elForm.regionName = addCity;
        }
        if (props.messageType == "edit") {
          // 修改直接赋值
          state.elForm.userName = itemMember.value.username; //用户名
          state.elForm.jobNumber = itemMember.value.jobNumber; //工号
          state.elForm.accountNumber = itemMember.value.account; //账号
          state.elForm.telephoneNumber = itemMember.value.phone; //手机号码
          state.elForm.roleName = itemMember.value.roleName; //角色
          state.elForm.id = itemMember.value.id; //用户id
          state.elForm.teamId = itemMember.value.teamId; //组织ID
          state.elForm.roleId = itemMember.value.roleId;
          state.elForm.organizationName = itemMember.value.teamId;
          let cityArr = [];
          let region = itemMember.value.region.split(";");
          for (let i = 0; i < region.length; i++) {
            cityArr.push(region[i]);
          }
          state.elForm.regionName = cityArr; //区域
        }
      });
    const state = reactive({
      elForm: {
        userName: "",
        teamId: "",
        telephoneNumber: "",
        regionName: [],
        accountNumber: "",
        roleName: "",
        id: "",
        jobNumber: "",
        roleId: "",
        organizationName: "",
      },
      // 修改cascader默认配置
      cityCasDer: {
        value: "name",
        label: "title",
        disabled: "disabled",
        checkStrictly: true,
        children: "children",
        expandTrigger: "hover",
      },
      // 修改cascader默认配置
      organizationCasDer: {
        value: "id",
        label: "teamName",
        checkStrictly: true,
        children: "children",
        expandTrigger: "hover",
      },
      formRules: {
        userName: [
          { required: true, message: t('885'), trigger: "change" },
        ],
        organizationName: [
          { required: true, message: t('1171'), trigger: "change" },
        ],
        telephoneNumber: [
          {
            required: false,
            validator: thephone,
            trigger: "blur",
          },
        ],
        regionName: [
          { required: true, message: t('213'), trigger: "change" },
        ],
        accountNumber: [
          { required: true, message: t('636'), trigger: "change" },
          {
            validator:memberAccountNumber,
            trigger:"blur"
          }        ],
        roleName: [
          { required: true, message: t('1172'), trigger: "change" },
        ],
        jobNumber: [
          {
            required: false,
            validator: memberJobNumber,
            trigger: "blur",
          },
        ],
      },
      //组织
      organization: props.treeData, //
      // 地区
      city: [],
      // 角色
      role: [],
    });
    if (chooseDepartmentId.value) {
      state.elForm.teamId = chooseDepartmentId.value;
      state.elForm.organizationName = chooseDepartmentId.value;
    }
    const elFormRef = ref(null);
    let obj = {};
    const methods = reactive({
      sava: async () => {
        // let title = "";
        let cityName = state.elForm.regionName; //区域
        if (props.messageType == "add") {
          obj.username = state.elForm.userName; //用户
          obj.account = state.elForm.accountNumber; //账号
          obj.jobNumber = state.elForm.jobNumber; //工号
          obj.phone = state.elForm.telephoneNumber; //手机号码
          let region = "";
          if (cityName.length == 1) {
            region = `${cityName[0]}`;
          } else if (cityName.length == 2) {
            region = `${cityName[0]};${cityName[1]}`;
          } else if (cityName.length == 3) {
            region = `${cityName[0]};${cityName[1]};${cityName[2]}`;
          }
          obj.region = region;
          obj.roleId = state.elForm.roleName; //角色id
          let len = state.elForm.organizationName.length - 1;
          obj.teamId = state.elForm.teamId
            ? state.elForm.teamId
            : state.elForm.organizationName[len];

          elFormRef.value.validate((valid) => {
            if (valid) {
              members.addMember(obj).then((res) => {
                if (res.resultStatus) {
                  ElMessage({
                    message: t('713'),
                    type: "success",
                  });
                  emit("update:isVisible", false);
                  emit("addChange");
                }
              });
            }
          });
        } else {
          let obj = {};
          obj.account = state.elForm.accountNumber; //账号
          obj.jobNumber = state.elForm.jobNumber; //工号
          obj.phone = state.elForm.telephoneNumber; //手机号码
          obj.region = `${cityName[0]};${cityName[1]};${cityName[2]}`; //拼接地区
          obj.roleId = state.elForm.roleId; //角色id
          console.log(state.elForm.organizationName);
          if (typeof state.elForm.organizationName == "object") {
            let len = state.elForm.organizationName.length - 1;
            obj.teamId = state.elForm.organizationName[len];
          } else {
            obj.teamId = state.elForm.organizationName;
          }
          obj.username = state.elForm.userName; //用户
          obj.id = state.elForm.id; //用户id
          elFormRef.value.validate((valid) => {
            if (valid) {
              members.editMember(obj).then((res) => {
                if (res.resultStatus) {
                  ElMessage({
                    message: t('714'),
                    type: "success",
                  });
                  emit("update:isVisible", false);
                  emit("addChange");
                }
              });
            }
          });
        }
        // }
        // if (title !== "") {
        //   ElMessage.warning(title);
        // }
      },
      // 组织事件city
      organiChange: (e) => {
        let len = state.elForm.organizationName.length - 1;
        state.elForm.teamId = e[len];
        teamRef.value.togglePopperVisible();
      },
      //选择地区
      cityChange: () => {
        let cityArr = JSON.stringify(state.elForm.regionName);
        localStorage.setItem("personCity", cityArr); //存本地
        cityRef.value.togglePopperVisible();
        // state.elForm.regionName = `${e[0]};${e[1]};${e[2]};`; //拼接地区
      },
      // 选择角色
      roleChange: (id) => {
        state.elForm.roleId = id;
        console.log(state.elForm.roleName);
      },
    });

    return {
      ...toRefs(state),
      ...toRefs(methods),
      elFormRef,
      cityRef,
      teamRef
    };
  },
});
</script>

<style lang="less" scoped>
/deep/.el-dialog__header .el-dialog__title {
  color: #ffffff !important;
}
/deep/.el-form-item {
  margin-bottom: 2rem;
}
/deep/.el-form-item .el-form-item__label {
  margin-bottom: 0;
  color: #ffffff;
}
/deep/.el-form--default.el-form--label-top .el-form-item .el-form-item__label {
  margin-bottom: 0;
}
.editbox {
  margin-bottom: 2.5rem;
}
.left {
  width: 20rem;
}
.right {
  width: 20rem;
}
/deep/ .el-input__wrapper {
  margin-top: 5px;
  height: 2.5rem;
  background: #001c31;
}
/deep/ .el-input__inner {
  color: #ffffff;
}
</style>
