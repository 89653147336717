<template>
  <!-- 添加/编辑成员 -->
  <ele-dialog
    :isVisible="isVisible"
    :dialogTitle="t('656')"
    :dialogWidth="'780px'"
    :dialogTop="'228px'"
    @on-cancel="$emit('update:isVisible', false)"
    @on-add="save"
  >
  </ele-dialog>
</template>

<script>
import { t } from '../../../languages';
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="less">
</style>